export const NLUserMailStateNames = {
	new: 0,
	feed: 1,
	inbox: 2,
	unsubscribed: 3,
	hidden: 4,
};

export enum TimeState {
	last24hrs = 0,
	last7days = 1,
	last30days = 2,
	allTime = 3,
}

export const MesageLabelAction = {
	read: "read",
	unread: "unread",
};

export namespace TimeState {
	export function getTimeString(timeState: TimeState): string {
		switch (timeState) {
			case TimeState.last24hrs:
				return "Last 24 hrs";
			case TimeState.last7days:
				return "Last 7 days";
			case TimeState.last30days:
				return "Last 30 days";
			case TimeState.allTime:
				return "All Time";
			default:
				return "";
		}
	}

	export function getTimeStateFromString(timeString: string | null): TimeState {
		if (!timeString) {
			return TimeState.last7days;
		}

		switch (timeString) {
			case "Last 24 hrs":
				return TimeState.last24hrs;
			case "Last 7 days":
				return TimeState.last7days;
			case "Last 30 days":
				return TimeState.last30days;
			case "All Time":
				return TimeState.allTime;
			default:
				return TimeState.last7days;
		}
	}
}

export enum UnreadState {
	off = 0,
	unread = 1,
	read = 2,
}

export namespace UnreadState {
	export function getUnreadBoolean(unreadState: UnreadState | undefined): boolean | undefined {
		switch (unreadState) {
			case UnreadState.off:
				return undefined;
			case UnreadState.read:
				return false;
			case UnreadState.unread:
				return true;
		}
	}
}

export enum ThemeState {
	auto = "auto",
	dark = "dark",
	light = "light",
}

export enum MessageIdType {
	Internal = "internal",
	OAuth = "oauth",
	Legacy = "legacy",
}

export enum MxResult {
	Gmail = "gmail", Outlook = "outlook"
}

export enum PremiumStatus {
	noPremium = 0,
	subscriptions = 1,
    internalTrial = 2,
    friendsAndFamily = 3,
    expiredInternalTrial = 4
}

export enum PremiumSource {
	stripe = "stripe",
	appStore = "appstore",
	playStore = "playstore",
	unknown = "unknown",
	manual = "manual"
}

export enum NLNotificationPreferenceType {
	noNotifications = 0,
	setTime = 1,
	asArrived = 2,
}

export enum UserDeletionReason {
	technical = "Technical issues using Meco",
	sync = "Gmail/Outlook sync issues",
	expectation = "Meco didn't meet expectations",
	stoppedNewsletters = "I stopped reading newsletters",
	stoppedMeco = "I no longer use Meco",
	anotherService = "I'm using another service",
	other = "Other",
}