import React, { useState, useEffect, FC, Fragment } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import IconButton from "@components/common/IconButton";
import Alert, { AlertState } from "@components/common/Alert";
import { Dialog, Transition } from "@headlessui/react";
import { deleteUser } from "@utils/managers/networking/NetworkManager";
import LoadingView, { LoadingState } from "@components/common/LoadingView";
import { UserDeletionReason } from "@models/Enums";
import ListboxView from "@components/common/ListboxView";
import * as userManager from "@utils/managers/UserManager";
import { useNavigate } from "react-router-dom";
import { recordEvent } from "@utils/managers/AnalyticsManager";

interface DeleteUserModalProps {
	isShow: boolean;
	onClose: () => void;
}

const DeleteUserModal: FC<DeleteUserModalProps> = ({ isShow, onClose }) => {
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
	});
	const navigate = useNavigate();

	const placeholderString = "Select a reason";

	const [selectedReason, setSelectedReason] = useState<string>(placeholderString);

	const allReasons: string[] = [placeholderString, ...Object.values(UserDeletionReason)];

	useEffect(() => {
		setSelectedReason(placeholderString);
	}, [isShow]);

	const onModalClose = () => {
		onClose();
	};

	const renderCard = () => {
		var reasonText = "Many issues can be resolved by reaching out to our customer support team. We’re here to assist you and will work to resolve your concern promptly!";
		var buttonTitle: string | undefined = "Contact Support";
		var buttonLink = "mailto:team@meco.app";

		switch (selectedReason) {
			case UserDeletionReason.technical:
				reasonText = "Many issues can be resolved by reaching out to our customer support team. We’re here to assist you and will work to resolve your concern promptly!";
				break;
			case UserDeletionReason.sync:
				reasonText = "If new newsletters aren’t appearing in Meco or newsletters added to Meco aren’t showing up in Gmail/Outlook, you can resolve this by resyncing your mailbox. To do this, navigate to Settings, go to the Connected Mailboxes section, select your mailbox, and click Resync. If the issue persists, please contact us at team@meco.app.";
				buttonTitle = undefined;
				break;
			case UserDeletionReason.expectation:
				reasonText = "If Meco did not meet your expectations, we'd love to hear your feedback. Let us know how we can improve.";
				break;
			case UserDeletionReason.stoppedNewsletters:
				reasonText = "If you've stopped reading newsletters, we're sorry to see you go! If there's anything we can do to make Meco more useful for you, please let us know. Your feedback is valuable to us.";
				break;
			case UserDeletionReason.stoppedMeco:
				reasonText = "If you’ve stopped using Meco, we’re sorry to see you go! If there’s anything we can do to bring you back, please let us know.";
				break;
			case UserDeletionReason.anotherService:
				reasonText = "If you’re switching to another service, we’d love to understand your decision. Let us know how we can improve.";
				break;
			case UserDeletionReason.other:
				reasonText = "If you have another reason, please share it with us. We value your feedback and would like to assist in any way we can.";
				break;
			case placeholderString:
				return null;
		}

		return (
			<div className="flex flex-col gap-3 bg-surface p-3 rounded-xl border border-primary-100">
				<div className="text-primary font-regular font-primary text-base text-center">{reasonText}</div>
				{buttonTitle && (
					<button className="text-success-green hover:text-success-green/80 transition ease-in-out duration-300 font-medium font-primary text-base text-center" onClick={() => window.open(buttonLink, "_blank")}>
						{buttonTitle}
					</button>
				)}
			</div>
		);
	};

	const displayConfirmation = () => {
		setAlertState({ isShow: true, title: "Confirm Account Deletion", message: "Deleting your account is permanent, and if you return in the future, you’ll need to set everything up again. Newsletters from connected inboxes will be redirected back to your original inbox, while any newsletters subscribed to through your Meco Inbox address will be permanently deleted. If you have an active PRO subscription, please cancel it before proceeding.", actionButton: "Delete account", dismissButton: "Cancel", actionButtonAction: () => deleteAccount() });
	};

	const deleteAccount = () => {
		recordEvent("MCO - User Delete Started", { user_delete_reason: selectedReason });

		setLoadingState({ isLoading: true, message: "Deleting account" });

		deleteUser()
			.then(() => {
				recordEvent("MCO - User Delete Completed", { user_delete_reason: selectedReason });
				setLoadingState({ isLoading: false });
				setAlertState({
					isShow: true,
					title: "Account deleted",
					message: "We'd love to see you back in the future!",
					actionButton: "OK",
					dismissButton: null,
					actionButtonAction: () => {
						userManager.logOutUser(true, () => {
							navigate("/login");
						});
					},
				});
			})
			.catch((error) => {
				recordEvent("MCO - User Delete Error", { user_delete_reason: selectedReason, error: error?.message ?? "" });
				setLoadingState({ isLoading: false });
				setAlertState({ isShow: true, title: "Uh oh! Something went wrong", message: "Please try again and contact us if the problem persists", actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
			});
	};

	return (
		<>
			<LoadingView loadingState={loadingState} />
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Transition as={Fragment} show={isShow}>
				<Dialog onClose={() => onClose()} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child className="w-full h-full" enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="m-auto w-full h-full max-w-lg transform overflow-hidden rounded-2xl bg-secondary border border-primary-200 text-left align-middle shadow-xl transition-all">
								<div className="absolute w-full bg-surface backdrop-blur-md top-0 left-0 flex flex-row justify-between items-center p-4 pb-3 border-b border-primary-200 z-20">
									<div className="text-primary font-medium font-primary text-xl">Delete Account</div>
									<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onModalClose()} />
								</div>
								<div className="flex flex-col gap-4 text-left h-full p-4 py-[105px] bg-secondary overflow-auto w-full">
									<ListboxView selected={selectedReason} setSelected={(selectionString) => setSelectedReason(selectionString)} options={allReasons} className="!w-full" />
									{renderCard()}
								</div>
								<div className="absolute bottom-0 w-full bg-surface backdrop-blur-md border-t border-primary-100 px-4 py-4 pt-3 z-20">
									<div className="flex flex-row justify-end">
										<button disabled={selectedReason === placeholderString} className={`${selectedReason === placeholderString ? "opacity-50" : "opacity-100"} text-white font-medium font-primary text-base focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-brand-red transition ease-in-out whitespace-nowrap`} onClick={() => displayConfirmation()}>
											Delete account
										</button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default DeleteUserModal;
