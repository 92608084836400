import { findHeroImage, findUnsubscribeLink } from "@utils/MailScraper";
import { ReaderModeChoices } from "@utils/ReaderModeChoices";
import { PartialSyncMessagesService } from "@utils/managers/backendMailManager/mailboxFunctions/PartialSyncMessagesService";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import moment from "moment";

export class NLProfile {
	id!: string;
	first_name?: string;
	last_name?: string;
	email!: string;
	created_at!: Date;
	picture_url?: string;
	locale?: string;
	notifications?: NLNotificationPreference;
	subscriptions?: any;
	internal_trial?: any;
	premium_status?: number;
	premium_enabled?: boolean;
	is_profile_setup!: boolean;
	is_publisher: boolean = false;
	mailbox_profiles!: NLMailboxProfile[];
	audio_profiles!: NLAudioProfile[];

	constructor(profile: NLProfile) {
		Object.assign(this, profile);

		if (profile.mailbox_profiles) {
			this.mailbox_profiles = profile.mailbox_profiles.map((mailboxProfile: NLMailboxProfile) => new NLMailboxProfile(mailboxProfile));
		}

		if (profile.audio_profiles) {
			this.audio_profiles = profile.audio_profiles.map((audioProfile: NLAudioProfile) => new NLAudioProfile(audioProfile));
		}

		if (profile.notifications) {
			this.notifications = new NLNotificationPreference(profile.notifications);
		}
	}
}

export class NLNotificationPreference {
	as_arrived?: NLNotificationAsArrivedType;
	set_time?: NLNotificationSetTimeType;

	constructor(notification: NLNotificationPreference) {
		if (notification.as_arrived) {
			this.as_arrived = new NLNotificationAsArrivedType(notification.as_arrived);
		}
		if (notification.set_time) {
			this.set_time = new NLNotificationSetTimeType(notification.set_time);
		}
	}
}

export class NLNotificationAsArrivedType {
	id!: string;
	type!: number;

	constructor(notificationAsArrived: NLNotificationAsArrivedType) {
		Object.assign(this, notificationAsArrived);
	}
}

export class NLNotificationSetTimeType {
	id!: string;
	type!: number;
	day!: number;
	hour!: number;
	minute!: number;

	constructor(notificationSetTime: NLNotificationSetTimeType) {
		Object.assign(this, notificationSetTime);
	}
}

export class NLMailboxProfile {
	id!: string;
	email_address!: string;
	is_default?: boolean;
	is_error?: boolean;
	is_profile_setup?: boolean;
	name?: string;
	type!: string;

	constructor(mailboxProfile: NLMailboxProfile) {
		if (mailboxProfile.is_error) {
			OauthSessionManager.sharedInstance().generateSignInRequiredError(mailboxProfile.id);
		}
		Object.assign(this, mailboxProfile);
	}
}

export enum NLMailboxProfileType {
	Gmail = "gmail",
	Outlook = "outlook",
	Internal = "internal",
}

export namespace NLMailboxProfileType {
	export function getByValue(value: string): NLMailboxProfileType | undefined {
		const result = (Object.keys(NLMailboxProfileType) as Array<keyof typeof NLMailboxProfileType>).find((key) => NLMailboxProfileType[key] === value);
		return result !== undefined && result !== "getByValue" ? NLMailboxProfileType[result] : undefined;
	}
}

export class NLUserMailState {
	id!: number;
	sender_address!: string;
	sender_name?: string;
	state_id!: number;
	is_whitelisted?: boolean;
	mailbox_profile_id!: string;
	updated_at!: Date;

	constructor(userMailState: NLUserMailState) {
		Object.assign(this, userMailState);
		const dateObject = moment(userMailState.updated_at).toDate();
		this.updated_at = dateObject;
	}
}

export class NLMail {
	id!: string;
	sender_address?: string;
	sender_name?: string;
	to_address?: string;
	subject?: string;
	receive_date!: number;
	list_unsubscribe?: string;
	body_html?: string;
	body_text?: string;
	reader_mode_html?: string;
	snippet?: string;
	history_id?: number;
	is_unread?: boolean;
	attachments?: any;
	image_url?: string;
	mailbox_profile_id!: string;

	constructor(mail: NLMail) {
		if (mail.body_html) {
			mail.image_url = findHeroImage(mail.body_html);

			if (!mail.list_unsubscribe) {
				mail.list_unsubscribe = findUnsubscribeLink(mail.body_html);
			}
		}
		Object.assign(this, mail);
	}
}

export class NLNewsletterCategory {
	id!: number;
	name?: string;
	emoji?: string;
	sort_index?: number;
	image_url?: string;
	is_user_picked?: boolean;

	constructor(newsletterCategory: NLNewsletterCategory) {
		Object.assign(this, newsletterCategory);
	}
}

export class NLUserGroup {
	id!: number;
	group_name?: string;
	group_index?: number;

	constructor(userGroup?: NLUserGroup) {
		if (userGroup) {
			Object.assign(this, userGroup);
		}
	}
}

export class NLUserSenderGroup {
	id!: number;
	sender_address!: string;
	user_group!: number;
	sender_address_index?: number;

	constructor(userSenderGroup?: NLUserSenderGroup) {
		if (userSenderGroup) {
			Object.assign(this, userSenderGroup);
		}
	}
}

export class NLOauthAccessToken {
	mailbox_profile_id!: string;
	access_token!: string;
	expires_in!: number;

	constructor(mailboxProfileId: string, accessToken: string, expiresIn: number) {
		const currentDateInMillis = new Date().getTime();
		const bufferInSeconds = 60 * 10;
		const secondsToAdd = expiresIn - bufferInSeconds;
		const millisecondsToAdd = secondsToAdd * 1000; // Convert seconds to milliseconds
		var expiresInMillis = currentDateInMillis + millisecondsToAdd;

		this.mailbox_profile_id = mailboxProfileId;
		this.access_token = accessToken;
		this.expires_in = expiresInMillis;

		Object.assign(this);
	}
}

export class OnboardingProfile {
	persistence: OnboardingPersistence;
	analytics: OnboardingAnalytics;

	constructor(mailboxProfile: NLMailboxProfile) {
		this.persistence = new OnboardingPersistence(mailboxProfile);
		this.analytics = new OnboardingAnalytics();
	}
}

class OnboardingPersistence {
	mailboxProfile!: NLMailboxProfile;
	statesArray: NLUserMailState[] = [];
	feedArray: number[] = [];
	categoryArray: number[] = [];
	oneClickSubscribeArray: NLNewsletter[] = [];
	lastGetUserMailStatesDateString: string | null = null;
	isNewsletterPickerOnboardingAnimationComplete: boolean = false;

	constructor(mailboxProfile: NLMailboxProfile) {
		this.mailboxProfile = mailboxProfile;
	}
}

class OnboardingAnalytics {
	displayedFeed = 0;
	displayedDiscover = 0;
	discoveredItems = 0;

	// Discover
	disReturnNewsletterCount = 0;
	disReturnNewsletterLimit = 0;
	disReturnedNewsletterIds: number[] = [];
	disReturnedNewsletterNames: string[] = [];

	disReturnedOCSCount = 0;
	disReturnedOCSNewsletterIds: number[] = [];
	disReturnedOCSNewsletterNames: string[] = [];

	disReturnedNOCSCount = 0;
	disReturnedNOCSNewsletterIds: number[] = [];
	disReturnedNOCSNewsletterNames: string[] = [];

	disSubscribedNewsletterCount = 0;
	disSubscribedNewsletterIds: number[] = [];
	disSubscribedNewsletterNames: string[] = [];

	disRecommendationEngineVersion = "";
	isPremium = false;

	selectedCategoriesIds: number[] = [];
	selectedCategoriesNames: string[] = [];
}

export class NLNewsletter {
	id: number = 0;
	name?: string;
	summary?: string;
	image_url?: string;
	subscribe_link?: string;
	website?: string;
	whitelisted_date?: string;
	unsubscribe_link?: string;
	logo?: string;
	is_whitelisted?: boolean;
	domain?: string;
	type?: string[];
	category?: NLNewsletterCategory[];
	sub_category?: NLNewsletterCategory;
	is_discoverable?: boolean;
	is_recommended?: boolean;
	discover_sort_index: number = 0;
	onboarding_sort_index: number = 0;
	badge: number = 0;
	one_click_subscribe_method?: number;
	onboarding_force_priority_level?: number;
	is_user_subscribed?: boolean;

	constructor(newsletter: NLNewsletter) {
		Object.assign(this, newsletter);
	}
}

export class NLRecommendedNewsletters {
	newsletters!: NLNewsletter[];
	newsletter_scores!: NLNewsletterScore[];
	recommendation_engine_version!: string;

	constructor(recommendedNewsletters: NLRecommendedNewsletters) {
		Object.assign(this, recommendedNewsletters);
		this.newsletters = recommendedNewsletters.newsletters.map((newsletter: NLNewsletter) => new NLNewsletter(newsletter));
		this.newsletter_scores = recommendedNewsletters.newsletter_scores.map((newsletterScore: NLNewsletterScore) => new NLNewsletterScore(newsletterScore));
	}
}

export class NLNewsletterScore {
	newsletter_id!: number;
	newsletter_name?: string;
	cat?: number | null;
	cat_score: number = 0.0;
	sub_cat: number[] = [];
	sub_cat_weighted_score: number = 0.0;
	eng_score?: string;
	eng_weighted_score: number = 0.0;
	campaign_priority_level: number = 0;
	force_priority_level: number = 0;
	combined_score: number = 0.0;

	constructor(newsletterScore: NLNewsletterScore) {
		Object.assign(this, newsletterScore);
	}
}

export enum NLUserMailStateNames {
	new = 0,
	feed = 1,
	inbox = 2,
	unsubscribed = 3,
	hidden = 4,
}

export class SyncMessagesProfile {
	allMailboxProfiles!: NLMailboxProfile[];
	baseMails!: NLMail[];
	historyIds!: Record<string, string>;
	fullSyncProfiles: NLMailboxProfile[];
	fullSyncMails?: NLMail[];
	partialSyncMessageResponses?: PartialSyncMessagesService.ResponseModel[];
	partialSyncDeletedMessageIds: string[];
	partialSyncAddedMessages?: NLMail[];

	constructor(allMailboxProfiles: NLMailboxProfile[], baseMails: NLMail[] | undefined, historyIds: Record<string, string> | undefined) {
		this.allMailboxProfiles = allMailboxProfiles;
		this.baseMails = baseMails ?? [];
		this.historyIds = historyIds ?? {};
		this.fullSyncProfiles = [];
		this.partialSyncDeletedMessageIds = [];
	}
}

export class DiscoverModel {
	category!: NLNewsletterCategory;
	recommendedNewsletters?: NLNewsletter[] | undefined;
	isRowLoading: boolean | undefined;

	constructor(model: DiscoverModel) {
		Object.assign(this, model);
	}
}

export class NLBookmark {
	id!: number;
	mail_id: string | undefined;
	mail_sender_address: string | undefined;
	mail_sender_name: string | undefined;
	mail_subject: string | undefined;
	created_at: string = "";
	bookmark_highlight: NLBookmarkHighlight | undefined;
	bookmark_link: NLBookmarkLink | undefined;
	bookmark_star: NLBookmarkStar | undefined;
	note: string | undefined;
	tags: string[] = [];
	is_deleted: boolean = false;

	constructor(bookmark?: NLBookmark) {
		if (bookmark) {
			Object.assign(this, bookmark);
			if (bookmark.bookmark_highlight) {
				this.bookmark_highlight = new NLBookmarkHighlight(bookmark.bookmark_highlight);
			}
			if (bookmark.bookmark_link) {
				this.bookmark_link = new NLBookmarkLink(bookmark.bookmark_link);
			}
			if (bookmark.bookmark_star) {
				this.bookmark_star = new NLBookmarkStar(bookmark.bookmark_star);
			}
		}
	}
}

export class NLBookmarkHighlight {
	id!: number;
	class_id: number = 0;
	text: string | undefined;
	start: number = 0;
	end: number = 0;
	serialized_selection: string = "";
	is_reader_mode: boolean = false;

	constructor(bookmarkHighlight?: NLBookmarkHighlight) {
		if (bookmarkHighlight) {
			Object.assign(this, bookmarkHighlight);
		}
	}
}

export class NLBookmarkLink {
	id!: number;
	page_url: string | undefined;
	page_title: string | undefined;
	page_description: string | undefined;
	page_image_url: string | undefined;

	constructor(bookmarkLink?: NLBookmarkLink) {
		if (bookmarkLink) {
			Object.assign(this, bookmarkLink);
		}
	}
}

export class NLBookmarkStar {
	id!: number;

	constructor(bookmarkStar?: NLBookmarkStar) {
		if (bookmarkStar) {
			Object.assign(this, bookmarkStar);
		}
	}
}

export class NLSearchResult {
	mailboxProfile!: NLMailboxProfile;
	senderName!: string;
	senderAddress!: string;
	isAdded!: boolean;

	constructor(searchMail: NLSearchResult) {
		Object.assign(this, searchMail);
	}
}

export interface NLWallpaper {
	imageId: number;
	image: string;
	sidebarColor: string;
	containerColor: string;
}

export class NLConsent {
	id!: number;
	key!: string;
	title: string | undefined;
	description: string | undefined;
	status!: boolean;
	required!: boolean;

	constructor(consent: NLConsent) {
		Object.assign(this, consent);
	}
}

export class NLCreateCheckout {
	price_id!: string;
	success_url!: string;
	cancel_url!: string;

	constructor(priceId: string, successUrl: string, cancelUrl: string) {
		this.price_id = priceId;
		this.success_url = successUrl;
		this.cancel_url = cancelUrl;
	}
}

export class NLCheckout {
	checkout_url!: string;
	session_id!: string;

	constructor(checkout: NLCheckout) {
		Object.assign(this, checkout);
	}
}

export interface NLPurchaseProduct {
	price_id: string;
	product_name: string;
	monthly_price: string | undefined;
	total_price: string;
	trial_days?: number | undefined;
	term: string;
	badge?: string | undefined;
}

export interface NLReaderMode {
	state: boolean;
	font: (typeof ReaderModeChoices.Font)[keyof typeof ReaderModeChoices.Font];
	fontSize: number;
	theme: (typeof ReaderModeChoices.Theme)[keyof typeof ReaderModeChoices.Theme];
}

export class NLAudioProfile {
	id!: string;
	group_id!: number;
	audio_error?: string;
	scheduled_hour!: number;
	scheduled_minute!: number;
	is_unread_only: boolean = false;
	is_notifications_enabled: boolean = false;
	is_enabled: boolean = true;

	constructor(audioProfile: NLAudioProfile) {
		Object.assign(this, audioProfile);
	}
}

export class NLAudioSection {
	id!: string;
	key!: string;
	name?: string;
	subtitle?: string;
	sort_index: number = 0;

	constructor(section: NLAudioSection) {
		Object.assign(this, section);
	}
}

export class NLAudioEpisode {
	id!: string;
	title?: string;
	summary?: string;
	streaming_url?: string;
	image_url?: string;
	is_generated_for_public: boolean = false;
	chapters: NLAudioEpisodeChapter[] = [];
	created_at!: string;
	resume_time: number = 0;
	duration!: number;
	has_listened: boolean = false;

	constructor(episode: NLAudioEpisode) {
		Object.assign(this, episode);

		if (episode.chapters) {
			this.chapters = episode.chapters.map((episode: NLAudioEpisodeChapter) => new NLAudioEpisodeChapter(episode));
		}
	}
}

export class NLAudioEpisodeChapter {
	id!: string;
	title?: string;
	image_url?: string;
	start_time?: number;
	end_time?: number;
	referenced_messages: NLAudioReferencedMessage[] = [];

	constructor(chapter: NLAudioEpisodeChapter) {
		Object.assign(this, chapter);

		if (chapter.referenced_messages) {
			this.referenced_messages = chapter.referenced_messages.map((referencedMessage: NLAudioReferencedMessage) => new NLAudioReferencedMessage(referencedMessage));
		}
	}
}

export class NLAudioSectionDetail {
	key!: string;
	episodes: NLAudioEpisode[] = [];

	constructor(sectionDetail: NLAudioSectionDetail) {
		Object.assign(this, sectionDetail);

		if (sectionDetail.episodes) {
			this.episodes = sectionDetail.episodes.map((episode: NLAudioEpisode) => new NLAudioEpisode(episode));
		}
	}
}

export class NLAudioReferencedMessage {
	message_id: string = "";
	message_subject?: string;
	sender_address?: string;
	sender_name?: string;

	constructor(referenceMessage: NLAudioReferencedMessage) {
		Object.assign(this, referenceMessage);
	}
}

export interface AudioModel {
	section: NLAudioSection;
	episodes?: NLAudioEpisode[];
	isRowLoading: boolean;
	pageIndex?: number | undefined;
	trial_days?: number | undefined;
}

export enum AudioProfileError {
    NoSendersFound = "no_senders_found",
    NoMesagesFound = "no_messages_found",
    NotProUser = "not_pro_user",
	Unengaged = "unengaged",
	GenerationError = "generation_error"
}

export enum AudioRowType {
	List, 
	User,
	UserCheckBack, 
	UserOnboarding,
	UserFirstEpisodeLoading,
	UserDeactivated,
	UserDeactivatedUnengaged,
	UserDeactivatedNotPro,
	UserError,
}

export interface AudioSenderModel {
	userMailState: NLUserMailState;
	mail?: NLMail;
}

export class AudioOnboardingProfile {
	selectedSenders: string[] = [];
	senderModelArray?: AudioSenderModel[]; 
	scheduledHour: number = 9;
	notifications_enabled: boolean = true;
	createdGroup?: NLUserGroup;

	constructor() {
	}
}