import { useLayout } from "@providers/LayoutContext";
import { ReactComponent as BackIcon } from "@images/back_icon.svg";
import IconButton from "../IconButton";
import { useNavigate } from "react-router";
import { MenuItem } from "./SidebarWrapper";
import { ReactComponent as SparkleIcon } from "@images/sparkle_icon.svg";
import { ReactComponent as MailboxesIcon } from "@images/mailboxes_icon.svg";
import { ReactComponent as GroupsIcon } from "@images/groups_icon.svg";
import { ReactComponent as FeedbackIcon } from "@images/feedback_icon.svg";
import { ReactComponent as PreferencesIcon } from "@images/preferences_icon.svg";
import { ReactComponent as HelpIcon } from "@images/help_icon.svg";
import { ReactComponent as LogOutIcon } from "@images/logout_icon.svg";
import { ReactComponent as SwitchIcon } from "@images/switch_icon.svg";
import * as userManager from "@utils/managers/UserManager";
import Alert, { AlertState } from "../Alert";
import { useState } from "react";
import { useAuth } from "@providers/AuthContext";

const SettingsSidebar = () => {
	const { authUser } = useAuth();
	const { navigationBarHeight, sideBarWidth } = useLayout();
	const navigate = useNavigate();
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});

	const mecoProTitle = "Meco PRO";
	const connectedMailboxesTitle = "Connected Mailboxes";
	const groupsTitle = "Groups";
	const publisherPortalTitle = "Switch to Publisher Portal";
	const giveFeedbackTitle = "Give Feedback";
	const preferencesTitle = "Preferences";
	const helpSupportTitle = "Help & Support";
	const logOutTitle = "Log Out";

	const mecoProItem: MenuItem = { icon: SparkleIcon, title: mecoProTitle, link: { link: "" } };
	const connectedMailboxesItem: MenuItem = { icon: MailboxesIcon, title: connectedMailboxesTitle, link: { link: "" } };
	const groupsItem: MenuItem = { icon: GroupsIcon, title: groupsTitle, link: { link: "" } };
	const publisherPortalItem: MenuItem = { icon: SwitchIcon, title: publisherPortalTitle, link: { link: "" } };
	const giveFeedbackItem: MenuItem = { icon: FeedbackIcon, title: giveFeedbackTitle, link: { link: "" } };
	const preferencesItem: MenuItem = { icon: PreferencesIcon, title: preferencesTitle, link: { link: "" } };
	const helpSupportItem: MenuItem = { icon: HelpIcon, title: helpSupportTitle, link: { link: "" } };
	const logOutItem: MenuItem = { icon: LogOutIcon, title: logOutTitle, link: { link: "" } };

	const menuItems: MenuItem[] = [mecoProItem, connectedMailboxesItem, groupsItem, giveFeedbackItem, preferencesItem, helpSupportItem, logOutItem];

	if (authUser && authUser.is_publisher) {
		menuItems.splice(3, 0, publisherPortalItem);
	}

	const buttonOnClick = (title: string) => {
		var sectionId: string | undefined;

		switch (title) {
			case mecoProTitle:
				sectionId = "meco_pro";
				break;
			case connectedMailboxesTitle:
				sectionId = "connected_mailboxes";
				break;
			case groupsTitle:
				sectionId = "groups";
				break;
			case publisherPortalTitle:
				window.location.replace("https://publishers.meco.app");
				break;
			case giveFeedbackTitle:
				sectionId = "feedback";
				break;
			case preferencesTitle:
				sectionId = "preferences";
				break;
			case helpSupportTitle:
				sectionId = "support";
				break;
			case logOutTitle:
				setAlertState({ isShow: true, title: "Are you sure?", message: "You will be logged out from your account", actionButton: "Log Out", dismissButton: "Cancel", actionButtonAction: () => logOut() });
				break;
		}

		if (!sectionId) return;
		const section = document.getElementById(sectionId);
		if (section) {
			section.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};

	const logOut = () => {
		userManager.logOutUser(true, () => {
			navigate("/login");
		});
	}

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<div className="flex flex-col items-center flex-shrink-0 flex-grow-0" style={{ flexBasis: sideBarWidth }}>
				<div className="sticky top-0 bg-contrast/5 backdrop-blur-lg flex flex-row justify-start gap-2 w-full items-center p-3 border-b border-primary-100" style={{ height: navigationBarHeight + "px" }}>
					<IconButton Icon={BackIcon} className="-ml-2.5" onClick={() => navigate("/")} />
					<div className="font-primary text-lg font-black text-primary text-left">Settings</div>
				</div>
				<div className="flex flex-col gap-2 w-full px-3 py-3">
					{menuItems.map((menuItem) => {
						return (
							<div key={menuItem.title} className="w-full flex flex-col gap-2 items-start">
								<button className={`w-full rounded-xl flex flex-row items-center gap-3 py-2 text-base font-primary font-medium text-primary h-[45px] hover:bg-primary-100 hover:border-1 hover:border-primary-100 transition-[background] ease-in-out duration-300`} onClick={() => buttonOnClick(menuItem.title)}>
									<div className="w-full flex flex-row justify-between items-center">
										<div className="flex flex-row items-center">
											<div className="w-[42px]">
												<menuItem.icon className="fill-primary w-[18px] h-[18px] m-auto opacity-50" />
											</div>
											<div>{menuItem.title}</div>
										</div>
									</div>
								</button>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default SettingsSidebar;
