import { ReactComponent as SettingsIcon } from "@images/settings_icon.svg";
import { ReactComponent as SidebarExpandIcon } from "@images/sidebar_expand_icon.svg";
import { ReactComponent as MecoLogo } from "@images/meco_logo.svg";
import { ReactComponent as ExpandArrow } from "@images/expand_arrow.svg";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import { ReactComponent as SearchIcon } from "@images/search_icon.svg";
import { ReactComponent as InboxIcon } from "@images/tabbar_inbox.svg";
import { ReactComponent as DiscoverIcon } from "@images/discover_icon.svg";
import { ReactComponent as SendersIcon } from "@images/tabbar_senders.svg";
import { ReactComponent as BookmarksIcon } from "@images/tabbar_bookmarks.svg";
import { ReactComponent as AudioIcon } from "@images/tabbar_audio.svg";
import IconButton from "../IconButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NLUserMailStateNames } from "@models/Enums";
import { NLUserGroup, NLUserMailState } from "@models/Models";
import { useEffect, useState } from "react";
import { useData } from "@providers/DataContext";
import { useSidebar } from "@providers/SidebarContext";
import { useLayout } from "@providers/LayoutContext";
import { SubMenuItem, LinkItem, MenuItem } from "./SidebarWrapper";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { useAudio, useAudioDispatch } from "@providers/AudioContext";
import { ReactComponent as PlayIcon } from "@images/audio_play_icon.svg";
import { ReactComponent as PauseIcon } from "@images/audio_pause_icon.svg";

const DefaultSidebar = () => {
	const { setSidebarHidden, navigationBarHeight, setSettingsActive, sideBarWidth } = useLayout();
	const { isLoadingGroups, userGroups, setActiveGroup, activeGroup, isLoadingStates, userMailStates, setActiveUserMailState, activeUserMailState, setActiveMail, isLoadingBookmarks } = useData();
	const { activeMenuItem, setActiveMenuItem, isShowExpand, setShowExpand } = useSidebar();
	const [subMenuExpandStates, setSubMenuExpandStates] = useState<{ [key: string]: boolean }>({});
	const { episode, isPlaying } = useAudio();
	const audioDispatch = useAudioDispatch();

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const sideBarIconVisible = [digestItem.link.link, bookmarksItem.link.link, searchItem.link.link];
		if (!sideBarIconVisible.includes(location.pathname)) {
			setSidebarHidden(false);
			resetSelections();
		}
		setShowExpand(sideBarIconVisible.includes(location.pathname));
	}, [location.pathname]);

	const getGroups = (): SubMenuItem[] | undefined => {
		if (userGroups) {
			var subMenuItems: SubMenuItem[] = [];

			for (let userGroup of userGroups) {
				const subMenuGroup: SubMenuItem = { key: `${userGroup.id}`, title: userGroup.group_name ?? "", action: () => onGroupAction(userGroup), isActive: userGroup.id === activeGroup?.id };
				subMenuItems.push(subMenuGroup);
			}

			return subMenuItems;
		}

		return undefined;
	};

	const getStates = (): SubMenuItem[] | undefined => {
		if (userMailStates) {
			var subMenuItems: SubMenuItem[] = [];
			const tempUserMailStates = userMailStates.filter((x) => x.state_id === NLUserMailStateNames.feed);
			tempUserMailStates.sort((a, b) => (a?.sender_name || "").localeCompare(b?.sender_name || ""));
			for (let state of tempUserMailStates) {
				const subMenuGroup: SubMenuItem = { key: `${state.id}`, title: state.sender_name ?? "", action: () => onSenderAction(state), isActive: state.id === activeUserMailState?.id };
				subMenuItems.push(subMenuGroup);
			}

			return subMenuItems;
		}

		return undefined;
	};

	const onGroupAction = (userGroup: NLUserGroup) => {
		resetSelections();
		userGroup == activeGroup ? setActiveGroup(undefined) : setActiveGroup(userGroup);
		recordEvent(kAnalyticsConstants.App.groupTapped, { group_id: userGroup.id, group_name: userGroup.group_name });
		if (location.pathname !== digestItem.link.link) {
			navigate("/");
		}
	};

	const onSenderAction = (userMailState: NLUserMailState) => {
		resetSelections();
		userMailState == activeUserMailState ? setActiveUserMailState(undefined) : setActiveUserMailState(userMailState);
		recordEvent(kAnalyticsConstants.App.senderTapped, { sender_id: userMailState.id, sender_name: userMailState.sender_name, sender_address: userMailState.sender_address });
		if (location.pathname !== digestItem.link.link) {
			navigate("/");
		}
	};

	const onSearchAction = () => {
		if (activeMenuItem?.link.link !== searchItem.link.link) {
			goToLink(searchItem.link.link);
		} else {
			goToLink(digestItem.link.link);
		}
	}

	const getSubMenuExpandState = (initial: boolean, menuItemTitle: string): boolean => {
		if (subMenuExpandStates[menuItemTitle] !== undefined) {
			return subMenuExpandStates[menuItemTitle];
		} else {
			setSubMenuExpandStates((prevStates) => ({
				...prevStates,
				[menuItemTitle]: initial,
			}));
			return initial;
		}
	};

	const toggleSubMenuExpandState = (menuItemTitle: string) => {
		setSubMenuExpandStates((prevStates) => ({
			...prevStates,
			[menuItemTitle]: !prevStates[menuItemTitle],
		}));
	};

	const resetSelections = () => {
		setActiveGroup(undefined);
		setActiveUserMailState(undefined);
		setActiveMail(undefined);
	};

	var digestLinkItem: LinkItem = { link: "/", isParentLink: false };
	var searchLinkItem: LinkItem = { link: "/search", isParentLink: false };
	var discoverLinkItem: LinkItem = { link: "/discover", isParentLink: false };
	var bookmarksLinkItem: LinkItem = { link: "/bookmarks", isParentLink: true };
	var sendersLinkItem: LinkItem = { link: "/senders", isParentLink: true };
	var settingsLinkItem: LinkItem = { link: "/settings", isParentLink: false };
	var audioLinkItem: LinkItem = { link: "/audio", isParentLink: false };

	const digestTitle = "Digest";
	const discoverTitle = "Discover";
	const bookmarksTitle = "Bookmarks";
	const sendersTitle = "Senders";
	const searchTitle = "Search";
	const audioTitle = "Audio";

	var digestItem: MenuItem = { icon: InboxIcon, title: digestTitle, subMenu: getGroups(), subMenuExpandState: getSubMenuExpandState(true, digestTitle), subMenuLoadingState: isLoadingGroups, link: digestLinkItem };
	var discoverItem: MenuItem = { icon: DiscoverIcon, title: discoverTitle, subMenu: undefined, subMenuExpandState: getSubMenuExpandState(false, discoverTitle), subMenuLoadingState: false, link: discoverLinkItem };
	var bookmarksItem: MenuItem = { icon: BookmarksIcon, title: bookmarksTitle, subMenu: undefined, subMenuExpandState: getSubMenuExpandState(false, bookmarksTitle), subMenuLoadingState: isLoadingBookmarks, link: bookmarksLinkItem };
	var sendersItem: MenuItem = { icon: SendersIcon, title: sendersTitle, subMenu: getStates(), subMenuExpandState: getSubMenuExpandState(false, sendersTitle), subMenuLoadingState: isLoadingStates, link: sendersLinkItem };
	var audioItem: MenuItem = { icon: AudioIcon, title: audioTitle, subMenu: undefined, subMenuExpandState: getSubMenuExpandState(false, audioTitle), subMenuLoadingState: false, link: audioLinkItem };
	
	var searchItem: MenuItem = { icon: SearchIcon, title: searchTitle, subMenu: undefined, subMenuExpandState: undefined, subMenuLoadingState: false, link: searchLinkItem };

	var menuItems: MenuItem[] = [digestItem, audioItem, discoverItem, bookmarksItem, sendersItem];

	useEffect(() => {
		if (location.pathname === digestLinkItem.link && activeUserMailState) {
			setActiveMenuItem(sendersItem);
			return;
		}

		const tempMenuItems = [...menuItems, searchItem];

		for (let menuItem of tempMenuItems) {
			if (menuItem.link.isParentLink && location.pathname.includes(menuItem.link.link)) {
				setActiveMenuItem(menuItem);
				break;
			}
			if (location.pathname === menuItem.link.link) {
				setActiveMenuItem(menuItem);
				break;
			}
		}
	}, [location, activeGroup, activeUserMailState]);

	const goToLink = (link: string) => {
		var eventName: string | undefined;
		switch (link) {
			case digestItem.link.link:
				eventName = kAnalyticsConstants.App.digestTapped;
				break;
			case audioItem.link.link:
				eventName = kAnalyticsConstants.App.audioTapped;
				break;
			case discoverItem.link.link:
				eventName = kAnalyticsConstants.App.discoverTapped;
				break;
			case bookmarksItem.link.link:
				eventName = kAnalyticsConstants.App.bookmarksTapped;
				break;
			case sendersItem.link.link:
				eventName = kAnalyticsConstants.App.sendersTapped;
				break;
			case searchItem.link.link:
				eventName = kAnalyticsConstants.App.searchTapped;
				break;
			case settingsLinkItem.link:
				eventName = kAnalyticsConstants.App.settingsTapped;
				break;
		}
		if (eventName) {
			recordEvent(eventName);
		}
		setActiveGroup(undefined);
		setActiveUserMailState(undefined);
		setActiveMail(undefined);
		navigate(link);
	};

	const playPauseOnClick = (e) => {
		e.stopPropagation();
		if (isPlaying) {
			audioDispatch({ type: "PAUSE" });
		} else {
			audioDispatch({ type: "PLAY" });
		}
	}

	const renderAudioBadge = (menuItem: MenuItem) => {

		if (menuItem.title != audioItem.title) { return }

		if (activeMenuItem?.title != audioItem.title && episode) {
			return <IconButton Icon={isPlaying ? PauseIcon : PlayIcon} className="mr-2 bg-success-green !w-[28px] !h-[28px] !rounded-full" iconClassName="!fill-white" forcedIconSize={12} onClick={(e) => playPauseOnClick(e)} />
		}

		return <div className="bg-success-green/20 rounded-lg px-2 py-1 text-xs font-primary font-bold text-primary mr-2">NEW</div>
	}

	return (
		<div className="flex flex-col items-center flex-shrink-0 flex-grow-0 overflow-y-auto" style={{ flexBasis: sideBarWidth }}>
			<div className="sticky top-0 bg-contrast/5 dark:bg-transparent backdrop-blur-lg flex flex-row justify-between w-full items-center p-3 border-b border-primary-100" style={{ height: navigationBarHeight + "px" }}>
				<MecoLogo className="fill-primary !h-[20px] cursor-pointer" onClick={() => goToLink(digestItem.link.link)} />
				<div className="flex flex-row">
					{isShowExpand && <IconButton Icon={SidebarExpandIcon} forcedIconSize={24} onClick={() => setSidebarHidden(true)} />}
					<IconButton Icon={SearchIcon} isActive={activeMenuItem?.link.link === searchItem.link.link}  onClick={() => onSearchAction()} />
					<IconButton Icon={SettingsIcon} buttonOnEdge={true} onClick={() => goToLink(settingsLinkItem.link)} />
				</div>
			</div>
			<div className="flex flex-col gap-2 w-full px-3 py-3">
				{menuItems.map((menuItem) => {
					return (
						<div key={menuItem.title} className="w-full flex flex-col gap-2 items-start">
							<button className={`${menuItem.title === activeMenuItem?.title ? "bg-primary-100 dark:bg-primary-200 border-1 border-primary-100" : null} w-full rounded-xl flex flex-row items-center gap-3 py-2 text-base font-primary font-bold text-primary h-[45px] hover:bg-primary-100 hover:border-1 hover:border-primary-100 transition-[background] ease-in-out duration-300`} onClick={() => goToLink(menuItem.link.link)}>
								<div className="w-full flex flex-row justify-between items-center">
									<div className="flex flex-row items-center">
										<div className="w-[42px]">
											<menuItem.icon className="fill-primary w-[18px] h-[18px] m-auto" />
										</div>
										<div>{menuItem.title}</div>
									</div>
									{menuItem.subMenuLoadingState ? (
										<LoadingSpinner className="w-[20px] text-primary-200 animate-spin fill-primary mr-2" />
									) : (
										menuItem.subMenu && (
											<button
												className={`w-[20px] h-[20px] mr-2.5`}
												onClick={(e) => {
													e.stopPropagation();
													toggleSubMenuExpandState(menuItem.title);
												}}>
												<ExpandArrow className={`fill-primary-500 transform-rotate duration-300 ease-in-out hover:fill-primary ${menuItem.subMenuExpandState ? "rotate-0" : "-rotate-180"}`} />
											</button>
										)
									)}

									{renderAudioBadge(menuItem)}
										
								</div>
							</button>
							{menuItem.subMenu && menuItem.subMenuExpandState && (
								<div className="flex flex-row w-full items-stretch gap-2">
									<div className="w-[25px] flex-shrink-0">
										<div className="border-r-[3px] h-full border-primary-200"></div>
									</div>
									<div className="w-[calc(100%-25px)] flex flex-col gap-2">
										{menuItem.subMenu.map((subMenuItem) => {
											return (
												<button key={subMenuItem.key} className={`${subMenuItem.isActive ? "bg-success-green/40 hover:bg-success-green/40" : null} w-full rounded-xl flex flex-col gap-3 px-3 py-1 text-base font-primary font-regular text-primary h-[36px] hover:bg-primary-100 hover:border-1 hover:border-primary-100 transition-all ease-in-out duration-300 justify-center truncate overflow-hidden text-ellipsis max-w-[230px]`} onClick={subMenuItem.action}>
													<div className="text-ellipsis overflow-hidden w-[calc(100%-25px)] text-left">{subMenuItem.title}</div>
												</button>
											);
										})}
									</div>
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default DefaultSidebar;
